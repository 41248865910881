@import "admin-variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/bootswatch/yeti/bootswatch";

body{
    background: #000;
}
body::after {
  content: "";
  background: url(../img/bg-login.jpg);
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.form-login{
    margin-top: 150px;
    .form-group{
        i.fa{
            width: 40px;
            font-size: 25px;
            color: $brand-primary
        }
    }
    .panel{
        border-radius: 1px;
        border: 0;
    }
    h4{
        margin-top: 10px;
        font-size: 20px;
        border-left: 10px solid $brand-primary;
        padding: 15px;
    }
}
